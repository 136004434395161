<template>
  <div class="file-viewer-container">
    <div v-if="isValidUrl" class="viewer-content">
      <img v-if="isImage" :src="url" alt="image file" class="media-file" />
      <audio v-if="isAudio" controls class="media-file">
        <source :src="url" :type="audioType" />
        Your browser does not support the audio element.
      </audio>
      <video v-if="isVideo" controls class="media-file">
        <source :src="url" type="video/mp4" />
        Your browser does not support the video element.
      </video>
      <div v-if="isPDF || isHTML" class="embed-container">
        <embed v-if="isPDF" :src="url" type="application/pdf" class="embedded-content" />
        <iframe v-if="isHTML" :src="url" class="embedded-content" frameborder="0"></iframe>
      </div>
    </div>

    <p v-else>{{ $t('invalid_url') }}</p>
  </div>
</template>

<script>
export default {
  name: 'FileViewerComponent',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      allowedExtensions: ['jpg', 'jpeg', 'mp3', 'wav', 'pdf', 'mp4', 'svg', 'html'],
      isValidUrl: false
    };
  },
  computed: {
    fileExtension() {
      const urlWithoutParams = this.url.split('?')[0];
      return urlWithoutParams.split('.').pop().toLowerCase();
    },
    isImage() {
      return ['jpg', 'jpeg', 'svg'].includes(this.fileExtension);
    },
    isAudio() {
      return ['mp3', 'wav'].includes(this.fileExtension);
    },
    isVideo() {
      return this.fileExtension === 'mp4';
    },
    isPDF() {
      return this.fileExtension === 'pdf';
    },
    isHTML() {
      return this.fileExtension === 'html';
    },
    audioType() {
      return `audio/${this.fileExtension}`;
    }
  },
  mounted() {
    this.validateUrl();
  },
  methods: {
    validateUrl() {
      try {
        const urlObj = new URL(this.url);
        const isAllowedExtension = this.allowedExtensions.includes(this.fileExtension);
        this.isValidUrl = isAllowedExtension && urlObj.protocol.startsWith('http');
      } catch (error) {
        this.isValidUrl = false;
      }
    },
  }
};
</script>

<style scoped>
.file-viewer-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.viewer-content {
  position: relative;
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px;
  padding-bottom: 0;
}

.media-file {
  max-width: 100%;
  max-height: 400px;
  display: block;
  margin: 0 auto;
}

.embed-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* Aspect ratio 4:3 */
  overflow: hidden;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.embedded-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
